import React from 'react'
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
} from './SidebarElements'

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='about' onClick={toggle}>
            ABOUT
          </SidebarLink>
          <SidebarLink to='Tokenomics' onClick={toggle}>
            TOKENOMICS
          </SidebarLink>
          <SidebarLink to='Roadmap' onClick={toggle}>
            UTILITIES
          </SidebarLink>
          <SidebarLink
            href='https://app.uniswap.org/#/swap?outputCurrency=0x&inputCurrency=eth'
            target='_blank'
            onClick={toggle}>
            BUY
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
