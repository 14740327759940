import React, { useState } from 'react'
import Video from '../../videos/video.mp4'
import { Button } from '../ButtonElements'
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  ImgWrap,
  Img,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from './HeroElements'
import {
  FaTelegram,
  FaEthereum,
  FaRegEnvelope,
  FaTwitter,
} from 'react-icons/fa'

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from '../Footer/FooterElements'

const HeroSection = () => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <ImgWrap>
          <Img src={'/logohor.gif'} alt='NANNIG Logo' />
        </ImgWrap>
          <SocialMediaWrap>
            <SocialIcons>
            <SocialIconLink href='https://t.me/nannig_erc' target='_blank' aria-label='Facebook'>
                <FaTelegram />
              </SocialIconLink>
              <SocialIconLink href='https://x.com/nannig_erc' target='_blank' aria-label='Twitter'>
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href='https://etherscan.io/token/0x' target='_blank' aria-label='Instagram'>
                <FaEthereum />
              </SocialIconLink>
              <SocialIconLink href='mailto:team@nannig.com' target='_blank' aria-label='Linkedin'>
                <FaRegEnvelope />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        <HeroP>Contract Address: 0x</HeroP>
        {/* <HeroBtnWrapper>
          <Button to="signup" onMouseEnter={onHover}  onMouseLeave={onHover} primary='true' dark='true'  smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            Know More {hover ? <ArrowForward /> : <ArrowRight />}{" "}
          </Button>
        </HeroBtnWrapper> */}
      </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection
