export const homeObjOne={
    id:'about',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'Nannig',
    headline:"Ginnan's Weird, Special, and Definitely Not-Normal Cousin",
    description: "In a universe where cats code, and some even moonlight as crypto celebrities (looking at you, Ginnan), there lies a mystery. A mystery so baffling, so 'special', it makes the Ethereum merge look like an easy Sudoku puzzle. Enter Nannig, Ginnan's 'retarded' cousin.",
    description2: "You see, Ginnan, Vitalik Buterin's majestic cat, is famous for being the regal feline ruler of Vitalik’s household. But Nannig? Oh boy. Nannig is that cousin who shows up at family reunions uninvited, wearing mismatched socks and enthusiastically gnawing on a napkin while the other cats roll their eyes. He's not normal. He's extra.",
    buttonLabel:'Join $NANNIG',
    externalUrl:'https://t.me/nannig_erc',

    imgStart:false,
    // img: require('../../images/svg-1.svg').default,
    img:'/6.gif',
    alt:'about',
    dark:true,
    primary:false,
    darkText:true,
};
export const homeObjTwo={
    id:'Donations',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'Why',
    headline:'NANNIG',
    description:'Why Nannig, you ask? Because sometimes, in the crazy world of meme coins, you don’t need logic. You need a cat who makes you laugh. Nannig is here to prove that even the strangest, weirdest, and most “special” cats deserve their own moon mission (or at least a launch to your MetaMask wallet).',
    description2:"In a world of perfectly groomed, hyper-intelligent meme coins, Nannig is the one rolling down a hill, drooling, and somehow... accidentally landing in the spotlight. $NANNIG isn’t about rocket science, it's about creating a space where all the weirdos can unite and celebrate the oddities that make us all special (especially in the crypto space, where 'normal' is overrated).",
    buttonLabel:'UNISWAP',
    externalUrl:'https://app.uniswap.org/#/swap?outputCurrency=0x&inputCurrency=eth',
    imgStart:true,
    // img: require('../../images/svg-2.svg').default,
    img:'/2.gif',
    alt:'Piggybank',
    dark:false,
    primary:false,
    darkText:true,
};
export const homeObjThree={
  id:'Vision',
  lightBg:true,
  lightText:false,
  lightTextDesc:false,
  topLine:'NANNIG',
  headline:'Rollercoaster Vision',
  description:"Let’s be real here. There’s no fancy vision. $NANNIG’s mission is simple: to bring joy, chaos, and confusion into your crypto wallet. We’re not here to break DeFi, revolutionize staking, or redefine finance (although, who knows, with Nannig's unpredictability, maybe we'll accidentally do it).",
  description2:"What Nannig does know is this: no matter how weird, strange, or 'special' you are, there’s a place for you in this digital wonderland of cat memes and decentralized dreams.",
  buttonLabel:'X',
  externalUrl:'https://x.com/nannig_erc',
  imgStart:false,
  // img: require('../../images/svg-1.svg').default,
  img:'10.gif',
  alt:'Car',
  dark:true,
  primary:false,
  darkText:true,
};
export const homeObjFour={
  id:'Tokenomics',
  lightBg:true,
  lightText:false,
  lightTextDesc:false,
  topLine:'NANNIG',
  headline:'TOKENOMICS',
  description:'Tokenomics? We’re still trying to teach Nannig how to count, but that doesn’t mean you can’t hold onto the magic of randomness.',
  description2:"Total Supply: 69 billion tokens (Nannig thought this was hilarious for some reason, so we rolled with it).",
  description3:"Taxes: 0% buy and 100% confusion (or something like that... don't ask Nannig to explain it).",
  description4:"Utility: Uh… does making you laugh count as utility? (Nannig thinks it does).",
  buttonLabel:'Chart',
  externalUrl:'https://www.dextools.io/app/en/ether/pair-explorer/0x?utm_source=telegram&utm_medium=bobbybuybot',

  buttonLabel2:'Contract',
  externalUrl2:'https://etherscan.io/token/0x',
  imgStart:true,
  // img: require('../../images/svg-2.svg').default,
  img:'/8.gif',
  alt:'Piggybank',
  dark:false,
  primary:false,
  darkText:true,
};
export const homeObjFive={
  id:'Philosophy',
  lightBg:true,
  lightText:false,
  lightTextDesc:false,
  topLine:'The Nannig Philosophy',
  headline:'Laugh First, Ask Questions Later',
  description:"While the world of crypto can be serious, full of technical analysis and complicated jargon, $NANNIG is here to shake things up and remind everyone that it’s OK to not take things so seriously. Sometimes, you just need to have fun — even if your idea of fun is creating a coin about a very special cat that has no idea what it's doing.",
  description2:"Let’s face it, we all have a little bit of Nannig in us — that goofy, awkward side that just wants to enjoy life, stumble through it, and maybe get lucky in the process. So, why not channel your inner Nannig and join the $NANNIG revolution?",
  buttonLabel:'TELEGRAM CHANNEL',
  externalUrl:'https://t.me/nannig_erc',
  imgStart:false,
  // img: require('../../images/svg-1.svg').default,
  img:'orig.gif',
  alt:'Car',
  dark:true,
  primary:false,
  darkText:true,
};