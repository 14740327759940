import React from 'react'
import Icon1 from '../../images/svg-4.svg'
import Icon2 from '../../images/svg-5.svg'
import Icon3 from '../../images/svg-6.svg'
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from './ServicesElements'

const Services = () => {
  const phase1 = [
    'What the heck is Nannig?',
  ]
  const phase2 = [
    'Do something dumb and funny.',
  ]

  const phase3 = [
    'Accidentally change the world? Or maybe just nap. TBD.',
  ]

  return (
    <ServicesContainer id='Roadmap'>
      <ServicesH1>ROADMAP</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={'/7.gif'} />
          <ServicesH2>Phase 1</ServicesH2>
          {phase1.map((item) => (
            <ServicesP>{item}</ServicesP>
          ))}
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={'/5.gif'} />
          <ServicesH2>Phase 2</ServicesH2>
          {phase2.map((item) => (
            <ServicesP>{item}</ServicesP>
          ))}
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={'/9.gif'} />
          <ServicesH2>Phase 3</ServicesH2>

          {phase3.map((item) => (
            <ServicesP>{item}</ServicesP>
          ))}
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services
