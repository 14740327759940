import React from 'react'
import { animateScroll as scroll } from 'react-scroll'

import { BlackLogo } from '../../images'
import {
  FaTelegram,
  FaEthereum,
  FaRegEnvelope,
  FaTwitter,
} from 'react-icons/fa'

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from './FooterElements'

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop()
  }

  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              <img
                src={BlackLogo}
                style={{ width: '160px' }}
                alt='BlackLab logo'
              />
            </SocialLogo>
            <WebsiteRights>
              NANNIG Token ETH © {new Date().getFullYear()} All rights reserved
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink href='https://t.me/nannig_erc' target='_blank' aria-label='Facebook'>
                <FaTelegram />
              </SocialIconLink>
              <SocialIconLink href='https://x.com/nannig_erc' target='_blank' aria-label='Twitter'>
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href='https://etherscan.io/token/0x' target='_blank' aria-label='Instagram'>
                <FaEthereum />
              </SocialIconLink>
              <SocialIconLink href='mailto:team@nannig.com' target='_blank' aria-label='Linkedin'>
                <FaRegEnvelope />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
