import React from 'react';
import scrollToTop from '../components/ScrollToTop';
import SignIn from '../components/Signin';

const SigninPage = () => {
    return (
        <>
            <scrollToTop/>
            <SignIn/>
        </>
    )
}

export default SigninPage;
